<template>
    <div v-if="show">
        <div class="card">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template>{{$t('title.detailYearlyForecast')}}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <b-button variant="warning" size="sm" class="mt-3"
                                      @click="handleBack()"
                                      v-b-tooltip.hover :title="$t('button.title.back')">
                                <i class="fa fa-arrow-left"></i> {{$t('button.back')}}
                            </b-button>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body" v-if="entity">
                <b-row>
                    <b-col sm="12">
                        <table class="table table-striped">
                            <tbody>
                            <tr>
                                <th>{{$t('column.title')}}</th>
                                <td>{{entity.title}}</td>
                            </tr>
                            <tr>
                                <th>{{$t('column.year')}}</th>
                                <td>{{entity.year}}</td>
                            </tr>
                            <tr>
                                <th>{{$t('column.createdAt')}}</th>
                                <td>{{$global.utcDateToLocalDate(entity.created_at) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col><!-- /.col -->
                    <b-col sm="12">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th width="250">
                                    {{$t('column.brand')}} / {{$t('column.model')}}
                                </th>
                                <th>{{$t('month.januaryShort')}}</th>
                                <th>{{$t('month.februaryShort')}}</th>
                                <th>{{$t('month.marchShort')}}</th>
                                <th>{{$t('month.aprilShort')}}</th>
                                <th>{{$t('month.may')}}</th>
                                <th>{{$t('month.juneShort')}}</th>
                                <th>{{$t('month.julyShort')}}</th>
                                <th>{{$t('month.augustShort')}}</th>
                                <th>{{$t('month.septemberShort')}}</th>
                                <th>{{$t('month.octoberShort')}}</th>
                                <th>{{$t('month.novemberShort')}}</th>
                                <th>{{$t('month.decemberShort')}}</th>
                                <th>{{$t('column.total')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(afd, index) in entity.annual_forecast_details">
                                <td>
                                    <span>{{afd.brand.title}} {{afd.model.title}}</span>
                                </td>
                                <td>
                                    {{afd.month_1}}
                                </td>
                                <td>
                                    {{afd.month_2}}
                                </td>
                                <td>
                                    {{afd.month_3}}
                                </td>
                                <td>
                                    {{afd.month_4}}
                                </td>
                                <td>
                                    {{afd.month_5}}
                                </td>
                                <td>
                                    {{afd.month_6}}
                                </td>
                                <td>
                                    {{afd.month_7}}
                                </td>
                                <td>
                                    {{afd.month_8}}
                                </td>
                                <td>
                                    {{afd.month_9}}
                                </td>
                                <td>
                                    {{afd.month_10}}
                                </td>
                                <td>
                                    {{afd.month_11}}
                                </td>
                                <td>
                                    {{afd.month_12}}
                                </td>
                                <td>
                                    {{afd.total}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col><!-- /.col -->
                    <b-col sm="12" class="mt-3" v-if="versions && versions.length > 0">
                        <h4><span class="text-capitalize">{{$t('title.versions')}}</span></h4>
                        <hr>
                        <b-row v-for="(v, index) in versions" :key="index">
                            <b-col sm="2">
                                <h5>{{v.title}} | {{$global.utcDateToLocalDate(v.year, 'YYYY', 'YYYY-MM-DD')}}</h5>
                            </b-col><!-- /.col -->
                            <b-col sm="10" class="mb-5">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th width="250">
                                            {{$t('column.brand')}} / {{$t('column.model')}}
                                        </th>
                                        <th>{{$t('month.januaryShort')}}</th>
                                        <th>{{$t('month.februaryShort')}}</th>
                                        <th>{{$t('month.marchShort')}}</th>
                                        <th>{{$t('month.aprilShort')}}</th>
                                        <th>{{$t('month.may')}}</th>
                                        <th>{{$t('month.juneShort')}}</th>
                                        <th>{{$t('month.julyShort')}}</th>
                                        <th>{{$t('month.augustShort')}}</th>
                                        <th>{{$t('month.septemberShort')}}</th>
                                        <th>{{$t('month.octoberShort')}}</th>
                                        <th>{{$t('month.novemberShort')}}</th>
                                        <th>{{$t('month.decemberShort')}}</th>
                                        <th>{{$t('column.total')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(vafd, index) in v.annual_forecast_details">
                                        <td>
                                            <span>{{vafd.brand.title}} {{vafd.model.title}}</span>
                                        </td>
                                        <td>
                                            {{vafd.month_1}}
                                        </td>
                                        <td>
                                            {{vafd.month_2}}
                                        </td>
                                        <td>
                                            {{vafd.month_3}}
                                        </td>
                                        <td>
                                            {{vafd.month_4}}
                                        </td>
                                        <td>
                                            {{vafd.month_5}}
                                        </td>
                                        <td>
                                            {{vafd.month_6}}
                                        </td>
                                        <td>
                                            {{vafd.month_7}}
                                        </td>
                                        <td>
                                            {{vafd.month_8}}
                                        </td>
                                        <td>
                                            {{vafd.month_9}}
                                        </td>
                                        <td>
                                            {{vafd.month_10}}
                                        </td>
                                        <td>
                                            {{vafd.month_11}}
                                        </td>
                                        <td>
                                            {{vafd.month_12}}
                                        </td>
                                        <td>
                                            {{vafd.total}}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-col><!-- /.col -->
                        </b-row><!-- /.row -->
                    </b-col>
                </b-row><!-- /.row -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
    </div>
</template>
<script>
import {request} from '../../../util/Request'

export default {
    props: ['id', 'handleBack'],
    data() {
        return {
            entity: null,
            versions: null,
            show: true
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
            this.getDetails(this.$route.query.oToken);
            this.getVersions(this.$route.query.oToken);
        }
    },
    methods: {
        async getDetails(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/annual/forecasts/client/detail/${id}`,
                })

                const {data} = response
                this.entity = data;
            } catch (error) {
                this.handleServerError(error)
            }
        },
        async getVersions(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/annual/forecasts/client/versions/${id}`,
                })

                const {data} = response
                this.versions = data;
            } catch (error) {
                this.handleServerError(error)
            }
        }
    }
}
</script>

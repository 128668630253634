<template>
    <div v-if="show">
        <div class="card" v-if="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.yearlyForecasts')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button @click="setOperation('add')" variant="info" size="sm"
                                                      :title="$t('button.title.addNewItem')"
                                                      v-if="$global.hasPermission('annualforecastsstore')" v-b-tooltip.hover>
                                                <i class="fe fe-plus"></i> {{$t('button.addNew')}}
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('annualforecastsview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="annual-forecasts-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('detail', record.item.id)" class="ml-1"
                               :title="$t('button.title.itemDetails')" v-if="$global.hasPermission('annualforecastsview')"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="record.item.approved === 0 && $global.hasPermission('annualforecastsdestroy')">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class="ml-1"
                                   :title="$t('button.title.deleteItem')"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                        </template>
                    </b-table><!-- /.b-table -->
                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[10, 50, 100]"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.annual-forecasts-table -->
                <div class="annual-forecasts-operation">
                    <a-drawer
                        placement="right"
                        :width="'80%'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationClose"
                        :visible="operation !== null && operation !== 'detail'"
                        :zIndex="10"
                        :title="$t(operationTitle)"
                    >
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <b-col lg="3" md="3" sm="12">
                                            <b-form-group
                                                :label="$t('input.title')+' *'"
                                                label-for="title"
                                                :invalid-feedback="formErrors.first('title')"
                                            >
                                                <b-form-input
                                                    id="title"
                                                    v-model="formFields.title"
                                                    type="text"
                                                    :state="((formErrors.has('title') ? false : null))"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col lg="3" md="3" sm="12">
                                            <b-form-group
                                                :label="$t('input.year')+' *'"
                                                label-for="year"
                                                :invalid-feedback="formErrors.first('year')">
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.years"
                                                    placeholder=""
                                                    v-model="formFields.year"
                                                    :class="[{'invalid is-invalid': (formErrors.has('year'))}]"
                                                    @select="handleYearChange()"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <b-row>
                                        <b-col sm="12">
                                            <table class="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th width="250">
                                                        {{$t('column.brand')}} / {{$t('column.model')}}
                                                    </th>
                                                    <th>{{$t('month.januaryShort')}}</th>
                                                    <th>{{$t('month.februaryShort')}}</th>
                                                    <th>{{$t('month.marchShort')}}</th>
                                                    <th>{{$t('month.aprilShort')}}</th>
                                                    <th>{{$t('month.may')}}</th>
                                                    <th>{{$t('month.juneShort')}}</th>
                                                    <th>{{$t('month.julyShort')}}</th>
                                                    <th>{{$t('month.augustShort')}}</th>
                                                    <th>{{$t('month.septemberShort')}}</th>
                                                    <th>{{$t('month.octoberShort')}}</th>
                                                    <th>{{$t('month.novemberShort')}}</th>
                                                    <th>{{$t('month.decemberShort')}}</th>
                                                    <th>{{$t('column.total')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(afd, index) in annual_forecast_details">
                                                    <td>
                                                        <span>{{afd.brand.title}} {{afd.model.title}}</span>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_1"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_2"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_3"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_4"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_5"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_6"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_7"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_8"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_9"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_10"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_11"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.month_12"
                                                            @focusout="calculateTotal(index, 'afd')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="afd.total">
                                                        </b-input>
                                                    </td>
                                                </tr>
                                                <tr v-for="(cbm, index) in client_brand_models">
                                                    <td>
                                                        <span>{{cbm.brand_title}} {{cbm.model_title}}</span>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_1"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_2"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_3"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_4"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_5"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_6"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_7"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_8"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_9"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_10"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_11"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.month_12"
                                                            @focusout="calculateTotal(index, 'cbm')"
                                                        >
                                                        </b-input>
                                                    </td>
                                                    <td>
                                                        <b-input
                                                            type="number"
                                                            v-model="cbm.total">
                                                        </b-input>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </b-col>
                                    </b-row>
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12" class="text-left">

                                        </b-col><!-- /b-col -->
                                        <b-col md="6" lg="6" sm="12">
                                            <b-button
                                                size="sm"
                                                type="submit"
                                                variant="primary"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.save')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{$t('button.save')}}
                                            </b-button>
                                            <b-button variant="warning" class="ml-3"
                                                      size="sm" @click="handleOperationClose()"
                                                      v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                            </b-button>
                                        </b-col><!-- /b-col -->
                                    </b-row>
                                </div>
                            </b-row><!--/b-row-->
                        </form><!--/form-->
                    </a-drawer>
                </div><!--/.annual-forecasts-detail-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.fromAddedDate')"
                                        label-for="fromDate">
                                        <b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.toAddedDate')"
                                        label-for="toDate">
                                        <b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->

                <div class="clients-detail-operation" v-if="operation !== null && operation === 'detail'">
                    <detail :id="listQueryParams.oToken" :handle-back="handleOperationClose"></detail>
                </div><!--/.clients-details-operation-->
    </div>
</template>
<script>
import ListingMixin from '../../../util/ListingMixin'
import Error from '../../../util/Error'
import {mapState} from 'vuex'
import Datepicker from 'vuejs-datepicker'
import {request} from '../../../util/Request'
import Treeselect from '@riophae/vue-treeselect'
import _ from 'lodash'
import Detail from "./detail";
import {dateToUtcDate} from "@/util/Utils";

const FORM_STATE = {
    title: null,
    year: null,
    annual_forecast_details: [],
    _method: 'post',
};

const FILTER_STATE = {
    visible: false,
    from_date: null,
    to_date: null,
};

const COLUMN_DEFINITION = (self) => [
    {
        label: self.$t('column.title'),
        key: 'title',
        sortable: true,
        sortKey: 'title',
    },
    {
        label: self.$t('column.year'),
        key: 'year',
        sortable: true,
        sortKey: 'year',
    },
    (self.$global.hasAnyPermission(['annualforecastsupdate', 'annualforecastsdestroy'])
        ? {
            label: self.$t('column.action'),
            class: 'text-right',
            key: 'action',
            width: 150,
        } : {}),
];

export default {
    mixins: [ListingMixin],
    components: {
        Datepicker,
        Treeselect,
        Detail
    },
    data() {
        return {
            operationTitle: 'title.yearlyForecasts',
            formFields: {...FORM_STATE},
            filters: {...FILTER_STATE},
            annual_forecast_details: [],
            client_brand_models: [],
            dateTimeFormat: 'YYYY',
            listUrl: '/annual/forecasts/client',
            columns: COLUMN_DEFINITION(this),
            show: true,
            dropdowns: {
                years: [
                    {id: 2021, label: '2021'},
                    {id: 2022, label: '2022'},
                    {id: 2023, label: '2023'},
                    {id: 2024, label: '2024'},
                    {id: 2025, label: '2025'},
                ]
            }
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.operation !== 'detail' && this.$route.query.oToken) {
            this.handleEditClick(this.$route.query.oToken);
        }

        this.$title = this.$t('topBar.navigations.modules.yearlyForecasts')
    },
    methods: {
        setOperation(operation = 'add', operationToken = null) {
            this.operationTitle = `title.${operation}YearlyForecast`;

            this.$router.replace({
                query: Object.assign({},
                    this.$route.query,
                    {
                        ...this.listQueryParams,
                        operation: operation,
                        oToken: operationToken,
                    },
                ),
            }).then(() => {
            }).catch(() => {
            })
        },
        async handleSubmit() {
            this.formErrors = new Error({})
            try {
                const response = await request({
                    url: this.formFields.id ? '/annual/forecasts/client/update' : '/annual/forecasts/client/create',
                    method: 'post',
                    data: {
                        ...this.formFields,
                        annual_forecast_details: this.annual_forecast_details,
                        client_brand_models: this.client_brand_models,
                    },
                })

                if (this.formFields.id) {
                    this.itemUpdated()
                } else {
                    this.itemAdded()
                }

                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        async handleEditClick() {

        },
        async handleDeleteClick(id) {
            try {
                const response = await request({
                    method: 'post',
                    url: '/annual/forecasts/client/delete',
                    data: {
                        id: id,
                    },
                })
                this.loadList(this.listQueryParams)
                this.itemDeleted()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.itemDeleteFailsBecsDependency()
                    return false;
                }

                this.itemDeleteFails()
            }
        },
        async loadAnnualForecastDetails() {
            this.annual_forecast_details = [];
            this.client_brand_models = [];
            let year = this.formFields.year;
            try {
                const response = await request({
                    method: 'get',
                    url: `/annual/forecasts/client/details/${year}`,
                })

                const {data} = response
                this.annual_forecast_details = data.annual_forecast_details ? data.annual_forecast_details : [];

                if(data.client_brand_models) {
                    _.map(data.client_brand_models, (item) => {
                        if(_.findIndex(this.annual_forecast_details, {model_id: item.model_id}) < 0) {
                            this.client_brand_models.push({
                                model_id: item.model_id,
                                model_title: item.model.title,
                                brand_id: item.client_brand.brand.id,
                                brand_title: item.client_brand.brand.title,
                                month_1: '',
                                month_2: '',
                                month_3: '',
                                month_4: '',
                                month_5: '',
                                month_6: '',
                                month_7: '',
                                month_8: '',
                                month_9: '',
                                month_10: '',
                                month_11: '',
                                month_12: '',
                                total: '',
                            })
                        }
                    })
                }
            } catch (e) {

            }
        },
        handleYearChange() {
            setTimeout(() => {
                this.loadAnnualForecastDetails();
            }, 300)
        },
        calculateTotal(key, type) {
            let total_amount = 0;
            if(type === 'afd') {
                _.map(this.annual_forecast_details, (item, index) => {
                    if(index === key) {
                        total_amount += item.month_1 ? parseInt(item.month_1) : 0;
                        total_amount += item.month_2 ? parseInt(item.month_2) : 0;
                        total_amount += item.month_3 ? parseInt(item.month_3) : 0;
                        total_amount += item.month_4 ? parseInt(item.month_4) : 0;
                        total_amount += item.month_5 ? parseInt(item.month_5) : 0;
                        total_amount += item.month_6 ? parseInt(item.month_6) : 0;
                        total_amount += item.month_7 ? parseInt(item.month_7) : 0;
                        total_amount += item.month_8 ? parseInt(item.month_8) : 0;
                        total_amount += item.month_9 ? parseInt(item.month_9) : 0;
                        total_amount += item.month_10 ? parseInt(item.month_10) : 0;
                        total_amount += item.month_11 ? parseInt(item.month_11) : 0;
                        total_amount += item.month_12 ? parseInt(item.month_12) : 0;
                        item.total = total_amount;
                    }
                })
            } else {
                _.map(this.client_brand_models, (item, index) => {
                    if(index === key) {
                        total_amount += item.month_1 ? parseInt(item.month_1) : 0;
                        total_amount += item.month_2 ? parseInt(item.month_2) : 0;
                        total_amount += item.month_3 ? parseInt(item.month_3) : 0;
                        total_amount += item.month_4 ? parseInt(item.month_4) : 0;
                        total_amount += item.month_5 ? parseInt(item.month_5) : 0;
                        total_amount += item.month_6 ? parseInt(item.month_6) : 0;
                        total_amount += item.month_7 ? parseInt(item.month_7) : 0;
                        total_amount += item.month_8 ? parseInt(item.month_8) : 0;
                        total_amount += item.month_9 ? parseInt(item.month_9) : 0;
                        total_amount += item.month_10 ? parseInt(item.month_10) : 0;
                        total_amount += item.month_11 ? parseInt(item.month_11) : 0;
                        total_amount += item.month_12 ? parseInt(item.month_12) : 0;
                        item.total = total_amount;
                    }
                })
            }
        },
        hasListAccess() {
            return this.$global.hasPermission('annualforecastsview')
        },
        getExtraParams() {
            return {
                filters: {
                    ...this.filters,
                    from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                },
            }
        },
        handleResetFilterClick() {
            this.filters = {...FILTER_STATE}
            this.isFilterApplied = 'reset'
            this.handleResetClick()
            this.loadList(this.listQueryParams)
        },
        afterCloseOperation() {
            this.formFields = {...FORM_STATE};
            this.annual_forecast_details.length = 0;
            this.client_brand_models.length = 0;
        },
    },
    computed: {
        ...mapState([
            'global',
            'settings'
        ]),
    },
}
</script>
<style lang="scss">
.min-24px {
    min-height: 20px;
}

@media screen and (max-width: 768px) {
    .ant-drawer-content-wrapper {
        width: 98% !important;
    }

    .min-24px {
        min-height:24px !important;
    }
}
</style>

